import React from 'react'



export default function Notice() {
  return (
    <p className="mt-2">
      Civic Hacker is collaborating with the <a className="text-red" target="_blank" href="https://www.nist.gov">National Institute of Standards and Technology (NIST)</a> in the <a className="text-red" target="_blank" href="https://www.nist.gov/artificial-intelligence/artificial-intelligence-safety-institute">Artificial Intelligence Safety Institute Consortium</a> to establish a new measurement science that will enable the identification of proven, scalable, and interoperable measurements and methodologies to promote development of trustworthy Artificial Intelligence (AI) and its responsible use. NIST does not evaluate commercial products under this Consortium and does not endorse any product or service used. Additional information on this Consortium can be found <a target="_blank" className="text-red" href="https://www.federalregister.gov/documents/2023/11/02/2023-24216/artificial-intelligence-safety-institute-consortium">in the FRN notice here</a>.
      </p>
  )
}
