
import { LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

import './story.css'

const symbolUrl = new URL(
  '../assets/ch-symbol.png',
  import.meta.url
);


export default function Story({summary, depth, contextHeader, largeHeader, mainImage, moreoverHeader, moreoverBody, options, internalAnchor, topMessage, topMessageSummary, contextColor="text-purple-400", useTexture=true, smallImage=false, isTop=false}) {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-6 lg:overflow-visible lg:px-0">
      {isTop && <h2 className="mb-6 text-4xl font-bold tracking-tight text-gray-900 text-center">{topMessage}</h2>}
      {isTop && <p className="mb-8 text-2xl tracking-tight text-gray-900 text-center">{topMessageSummary}</p>}
      {useTexture && <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="story-g absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
        </svg>
      </div>}
      <div className={`pt-12 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none ${smallImage ? 'lg:grid-cols-3': 'lg:grid-cols-2'} lg:items-start lg:gap-y-10`}>
        <div className={`lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl ${smallImage ? '': 'lg:grid-cols-2'} lg:gap-x-8 lg:px-8`}>
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p id={internalAnchor} className={`text-base font-semibold leading-7 ${contextColor}`}>{contextHeader}</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{largeHeader}</h1>
              <p className={`${summary ? '': 'hidden'} mt-6 text-xl leading-8 text-gray-900`}>
                {summary}
              </p>
            </div>
          </div>
        </div>
        <div className={`-ml-0 p-0 lg:sticky lg:top-4 ${smallImage ? 'lg:col-start-3': 'lg:col-start-2 -mt-12' } lg:row-span-2 lg:row-start-1 lg:overflow-visible`}>
          <img
            className="rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem] sm:mx-auto"
            src={mainImage}
            alt=""
          />
        </div>
        <div className={` ${smallImage ? '': 'lg:grid'} lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8`}>
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className={` ${depth ? '': 'hidden'}`}>
                {depth}
              </p>
              <ul role="list" className="list-inside list-disc mt-8 space-y-6 text-gray-600">
                {options && options.map((e, i) =>
                <li key={i} className="gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">{e.name}</strong> {e.description}
                  </span>
                </li>
                )}
              </ul>
              <h2 className={`mt-16 text-2xl font-bold tracking-tight text-gray-900 ${moreoverHeader ? '' : 'hidden'}`}>{moreoverHeader}</h2>
              <p className={`mt-6 ${moreoverBody ? '' : 'hidden'}`}>
                {moreoverBody}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
