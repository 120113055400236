import { createRoot } from 'react-dom/client';
import React from 'react';
import { useState } from 'react';
import { Dialog } from '@headlessui/react'
import Footer from './components/Footer'
import Story from './components/Story'
import Notice from './components/Notice'
import './index.css'
import './landing.css'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';


let { NODE_ENV } = process.env;

var faro = initializeFaro({
  url: '/t',
  app: {
    name: 'humanquirks',
    version: '1.0.0',
    environment: NODE_ENV
  },
  instrumentations: [
    ...getWebInstrumentations(),
    new TracingInstrumentation(),
  ],
});


const armedUrl = new URL(
  './assets/defenses-framed-logo-v1.png',
  import.meta.url
);

const collisionUrl = new URL(
  './assets/collisions-framed-logo-v6.png',
  import.meta.url
);

const quirksUrl = new URL(
  './assets/quirks-framed-logo-v2.png',
  import.meta.url
);
const goalBrandedUrl = new URL(
  './assets/ecosystem-antioppressive-with-logo-v1.png',
  import.meta.url
);

const whiteSymbolUrl = new URL(
  './assets/ch-logo-white.png',
  import.meta.url
);


const humanQuirksUrl = new URL(
  './assets/human-quirks-horizontal-v2.png',
  import.meta.url
);


const container = document.getElementById('app');
const root = createRoot(container);

const heroDescription = "A project to explore how AI systems impact our collective well-being and livelihood";

const navigation = [
  { name: 'Meet the Quirks', href: '#meetthequirks', targetBlank: false, additionalClassNames: "humanquirks-meetthequirks" },
  { name: 'About the Prototype', href: '#guidingquestions', targetBlank: false, additionalClassNames: "humanquirks-more" },
  { name: 'Resources', href: 'https://docs.humanquirks.com', targetBlank: true, additionalClassNames: "humanquirks-resources" },
]

const armedOptions = [
  { name: "Adversarial Machine Learning", description: ""},
  { name: "AI Risk Management Framework", description: ""},
  { name: "Open Standards", description: ""},
  { name: "Laws & Regulation", description: ""},
]

const collisionOptions = [
  { name: "Human-Computer Interaction (HCI)", description: "- An extension of the Seven-Layer OSI Model depicting Human use of interfaces to achieve Human need through Human performance."},
  { name: "Open Systems Interconnection (OSI) Model", description: ""},
  { name: "Transmission Control Protocol/Internet Protocol (TCP/IP) Model", description: ""},
]

const quirkOptions = [
  { name: "Algorthmic Injustice", description: ""},
  { name: "copyright protection", description: ""},
  { name: "gender-based violence", description: ""},
]

const HumanQuirksDescription = "Human Quirks are technology-sensitive social phenomena that can impact both individual and collective behavior."
const HumanQuirksConcernSummary = "The emerging technologies we create and use have a profound impact on our multiple facets of lives. The impact of emerging technologies, like Artifical Intelligence, manifests differently based on individual lived experiences and unique interactions with communities for each of these domains of interest:"

const HumanQuirksConcerns = [
  { name: "Agency", description: ""},
  { name: "Climate Change", description: ""},
  { name: "Free Press", description: ""},
  { name: "Healthcare System", description: ""},
  { name: "Interpersonal Abuse", description: ""},
  { name: "Literacy", description: ""},
  { name: "Privacy", description: ""},
  { name: "Social Media", description: ""},
  { name: "Use of Force", description: ""},
]


function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
     <div className="bg-gray-900">
          <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
              <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Civic Hacker</span>
                  <img
                    className="h-8 w-auto"
                    src={whiteSymbolUrl}
                    alt="Civic Hacker logo"
                  />
                </a>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href}
                    target={item.targetBlank ? '_blank': '_self'}
                    className={`${item.additionalClassNames} text-sm font-semibold leading-6 text-white`}>
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                <div className="flex items-center justify-between">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Civic Hacker</span>
                    <img
                      className="h-8 w-auto"
                      src={whiteSymbolUrl}
                      alt="Civic Hacker logo"
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/25">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          target={item.targetBlank ? '_blank': '_self'}
                          className={`${item.additionalClassNames} -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800`}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          <div className="relative isolate pt-14">
            <div className="pb-4 pt-8 sm:pb-2 sm:pt-12 lg:pb-8">
              <div className="mx-auto max-w-7xl md:max-w-2xl lg:max-w-3xl px-8 lg:px-12">
                <div className="mx-auto max-w-2xl text-center">
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl flex flex-col space-y-1">
                    <span>Human Quirks</span>
                  </h1>
                  <p className="mt-6 text-3xl leading-8 text-white">
                    {heroDescription}
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6 mb-6">
                    <a href="/signup"
                      className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                    >
                      Subscribe for Updates
                    </a>
                  </div>
                </div>
                <img
                  src={goalBrandedUrl}
                  alt="An image showing Civic Hacker's prototype of the Wide-area detection system."
                  width={2432}
                  height={1442}
                  className="mt-2.5 rounded-md bg-white/5 ring-1 ring-white/10 sm:mt-4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative max-w-5xl text-base leading-7 text-gray-700 lg:max-w-5xl w-full mx-auto px-4 lg:px-0 flex flex-col">
      <h2 className="hidden mb-10 text-4xl font-bold tracking-tight text-gray-900 text-center">Here are our notes</h2>
            <p className="hidden mt-2">
                We face obstacles in improving school safety due to extreme partisanship from both sides. But together, we can work towards finding solutions that are fair and inclusive for all members of our community. Your input and support will help us in our ongoing efforts to research non-partisan and anti-oppressive strategies to tackle these issues.
            </p>
            <a href="https://buy.stripe.com/9AQ16Xbas6c0f5ufZ0" target="_blank" type="button" className="hidden humanquirks-plink mt-6 mx-auto text-center w-72 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">Yes, Let's work together</a>
        </div>
        <Story
            isTop={true}
            topMessage="According to Our Calculations"
            topMessageSummary="Here's a method of sociotechnical analysis that centers Humanity"
            mainImage={humanQuirksUrl}
            contextHeader="Answer"
            contextColor="text-red"
            largeHeader="Human Quirks"
            summary={HumanQuirksDescription}
            depth={HumanQuirksConcernSummary}
            options={HumanQuirksConcerns}
            internalAnchor="meetthequirks"
        />
        <div className="relative max-w-3xl text-base leading-7 text-gray-700 lg:max-w-4xl w-full mx-auto px-6 lg:px-0 flex flex-col my-8">
            <h2 className="mt-8 text-3xl font-bold tracking-tight text-gray-900 text-center">We've Joined forces with NIST</h2>

            <Notice />
        </div>
        <Story
            isTop={true}
            topMessage="About the Prototype"
            topMessageSummary="Follow along to get an idea of the protoype."
            mainImage={quirksUrl}
            contextHeader="Guiding Question 1"
            contextColor="text-purple"
            largeHeader="Which of our collective social traits or Quirks are sensitive to the advances of AI?"
            summary="Quirks are patterns of behavior pertaining to cultural archetypes, Human-made systems, physiology, visual/auditory perception, and social avenues of the Human experience."
            depth="Quirks, both externalized or internalized peculiars of the Human experience, have a location within Bronfeenbronner's Social Ecological model. By centering the person, we have a greater chance that we're attending to the parts that make us Human, for instance:"
            options={quirkOptions}
            internalAnchor="guidingquestions"
        />
        <Story
            mainImage={collisionUrl}
            contextHeader="Guiding Question 2"
            contextColor="text-red"
            largeHeader="Is there a way to steer how our Quirks and Web-based technologies interact to improve the lives of others?"
            summary="Human-Computer Interaction applies to both new creations as well the consumption of a technology. The User Interfaces we build can encourage both appropriate and inappropriate behaviors. "
            depth="The OSI and TCP/IP models represent layers 1 through 7. In practice, we build user interfaces (e.g., visual, auditory, touch/gesture) to serve Human peformance and needs representing layers 8, 9, and 10, respectively. We think Human Quirks interact with technology within an eleventh layer."
            options={collisionOptions}
        />
        <Story
            mainImage={armedUrl}
            contextHeader="Guiding Question 3"
            contextColor="text-purple"
            largeHeader="How do we fortify against the negative impact of AI while still encouraging innovation?"
            summary="Defenses may be active, passive and may come in the form of laws, standards, software, data, and other methodologies to deter the misuse of AI."
            depth="A number of efforts from new regulations to adversarial machine learning techniques:"
            options={armedOptions}
        />
      {/*
        <Story
            mainImage={collisionUrl}
            contextHeader="Guiding Question 4"
            contextColor="text-red"
            largeHeader="Mutation of Quirks"
            summary="When a marked firearm enters the perimeter, notifications are sent to authorities and staff both inside & outside the perimeter."
            depth="There are a number of readily-available technologies well-execercised emergency response techniques. To build a system that can confirms the firearm's presence and send notifications to can leverage analog, digital, and cloud-native protocols such as MQTT, TCP/IP, and UDP for communication over large distances. Communication within the perimeter can leverage digital and analog communication channels such as auditory, visual, cellular, radio, and wifi."
            moreoverHeader="Exploring markers for firearms"
            moreoverBody="The choice of markers for firearms will drive the deployment of portions of the internal perimeter communication of WAGS. This document assumes that markers are strictly designed to detect perimeter breaches and not necessarily to identify the firearm user or triangulate the firearm's location relative to the structure. As a part of the effort to design a marker, the implementer must remember that it must be possible to retrofit existing firearms with the marker. Identifying and testing various markers may be most feasible when Private-Public partnerships and 'Wisdom of the Crowd' techniques such as Hackathons are used. Please consider all sorts of marker compositions and applications such as electronic, flourescent, even chemical markers sush as some CORMs."
            options={trippedOptions}
        />
        */}
        {/*
        <div className="flex h-[705px] md:h-[450px] lg:h-96" id="top-of-funnel">
          <iframe src="https://app.civichacker.com/campaign/signup/humanquirks-com/" sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups" loading="lazy" className="flex-1" scrolling="no"></iframe>
        </div>
        */}
        <Footer />
      </>
  )
}

root.render(<Landing />);
